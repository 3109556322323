<template>
  <div v-if="loanTypes.length > 0" id="loanspot-wrapper">
    <div class="flex logo-wrapper">
      <img src="https://loanspot.africa/assets/images/LoanSpot-logo.svg" />
    </div>
    <h3 class="meta">
      Need a personal or business loan?
    </h3>
    <h3 class="meta">
      Check for best loan offers
    </h3>
    <h3 class="meta cta">
      Check below now
    </h3>
    <div class="form-wrap">
      <form
        @submit.prevent="submit"
        id="search-form"
        action="https://app.loanspot.africa/search"
      >
        <div class="amount-wrap">
          <label for="" class="input-label"
            >How much do you want to borrow?</label
          >
          <div class="input-wrap" id="borrow-input-wrap">
            <input
              type="text"
              class="input"
              id="borrow-amount-input"
              autocomplete="off"
              name="friendlyAmount"
              @keyup.prevent="($evt) => collectAmount($evt, 'amount')"
              v-model="form.friendlyAmount"
            />
            <input
              type="text"
              class="input"
              style="display: none"
              id="borrow-amount-input"
              autocomplete="off"
              name="amount"
              v-model="form.amount"
            />
            <div class="input--currency-wrap">
              <select
                name="loanCurrency"
                id="loanCurrency"
                class="input--currency"
              >
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="form.amount > 0" class="income-wrap">
          <label for="" class="input-label"
            >What is your net monthly income?</label
          >
          <div class="input-wrap" id="income-input-wrap">
            <input
              type="text"
              class="input"
              id="income-amount-input"
              autocomplete="off"
              name="income"
              @keyup.prevent="($evt) => collectAmount($evt, 'income')"
              v-model="form.friendlyIncome"
            />
            <input
              type="text"
              class="input"
              style="display: none"
              id="borrow-amount-input"
              autocomplete="off"
              name="income"
              v-model="form.income"
            />
            <div class="input--currency-wrap">
              <select
                name="incomeCurrency"
                id="incomeCurrency"
                class="input--currency"
              >
                <option value="NGN">NGN</option>
                <option value="USD">USD</option>
              </select>
            </div>
          </div>
        </div>
        <div v-if="form.amount > 0 && form.income > 0" class="loan-type-wrap">
          <span class="input-label">Type of loan</span>
          <div class="loan-type-radio">
            <div
              v-for="type in loanTypes"
              :key="type.id"
              @click.prevent="
                loanType = type.id;
                loanTypeName = type.short;
              "
            >
              <label
                :for="'loan-type-' + type.short"
                :id="'type-' + type.short"
                class="input-wrap type-business"
                :class="[
                  'type-' + type.short,
                  loanType === type.id ? 'selected' : '',
                ]"
                >{{ type.name }}</label
              >
              <!-- <input type="radio" :id="'loan-type-'+type.short" class="input" :value="type.id"> -->
              <input
                name="loanType"
                type="hidden"
                class="input"
                :value="type.id"
              />
            </div>
          </div>
        </div>
        <div v-if="loanType" class="purpose-wrap">
          <label for="" class="input-label"
            >What is the purpose of this loan?</label
          >
          <div class="input-wrap input-wrap--purpose">
            <select
              name="loanPurpose"
              id="input--purpose"
              class="input--purpose"
              v-model="form.loanPurpose"
            >
              <option :value="null">Choose</option>
              <option
                v-for="purpose in dropdownOptions[loanTypeName]"
                :key="purpose.id"
                :value="purpose.id"
              >
                {{ purpose.title }}
              </option>
            </select>
          </div>
        </div>
        <input type="hidden" name="page" value="1" />
        <input type="hidden" name="limit" value="10" />
        <input type="hidden" name="existingLoan" value="no" />
        <button
          type="submit"
          v-if="form.amount > 0 && form.income > 0 && loanType !== null"
          id="search-btn"
          class="form-btn"
        >
          Search
        </button>
      </form>
    </div>
  </div>
</template>

<script>
  import './style.css';
  import numeral from "numeral";
  let API_URL = "https://api.loanspot.africa";

  // if (window.location.href !== "http://localhost:8080/") {
  // 	API_URL = "http://localhost:3000";
  // }

  export default {
    name: "Loanspot",
    props: ["referrerId", "source"],
    data() {
      return {
        dropdownOptions: {},
        loanTypes: [],
        loanType: null,
        form: {
          loanPurpose: null,
          amount: null,
          friendlyAmount: 0,
          income: null,
          friendlyIncome: 0,
        },
      };
    },
    async beforeMount() {
      await this.fetchLoanPurposes();
      await this.fetchLoanTypes();
    },
    mounted() {
      const fontImport = document.createElement("link");
      this.setAttributes(fontImport, [
        {
          name: "href",
          value:
            "https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;400&display=swap",
        },
        { name: "rel", value: "stylesheet" },
        { name: "type", value: "text/css" },
      ]);
      document.head.appendChild(fontImport);
    },
    methods: {
      setAttributes(elem, attrs) {
        attrs.forEach((attr) => {
          elem.setAttribute(attr.name, attr.value);
        });
      },
      async submit($evt) {
        try {
          const payload = {
            amount: this.form.amount,
            income: this.form.income,
            loanType: this.loanType,
            existingLoan: "no",
            loanPurpose: this.form.loanPurpose,
            page: 1,
            limit: 10,
            referrerId: this.referrerId,
          };
          window.open(
            "https://app.loanspot.africa/search?" +
              new URLSearchParams(payload).toString() +
                `&utm_source=${this.source}&utm_medium=widget&utm_campaign=loanspot-widget&utm_content=loan-requests`
          );
          await fetch(
            "https://loanspot-analytics-api-gh9x7.ondigitalocean.app/analytics",
            {
              method: "POST",
              mode: "cors",
              cache: "no-cache",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                ...payload,
                page: undefined,
                limit: undefined,
                referrerUrl: window.location.origin,
              }),
            }
          );
        } catch (error) {
          console.log(error);
        } finally {
          $evt?.target?.reset();
          this.form = {
            loanPurpose: null,
            amount: null,
            friendlyAmount: 0,
            income: null,
            friendlyIncome: 0,
          };
          this.loanType = null;
        }
      },
      collectAmount($evt, scope) {
        const value = parseFloat($evt.target.value.replaceAll(",", ""));
        switch (scope) {
          case "amount":
            this.form.amount = value;
            this.form.friendlyAmount = numeral(value.toString()).format("0,0");
            break;
          case "income":
            this.form.income = value;
            this.form.friendlyIncome = numeral(value.toString()).format("0,0");
            break;

          default:
            break;
        }
      },
      async fetchLoanPurposes() {
        try {
          const response = await (await fetch(API_URL + "/loan-purposes")).json();
          this.dropdownOptions.business = response.data.filter(
            (p) => p.loanType.slug === "business"
          );
          this.dropdownOptions.individual = response.data.filter(
            (p) => p.loanType.slug === "individual"
          );
        } catch (error) {
          // console.log(error);
        }
      },

      async fetchLoanTypes() {
        try {
          const response = await (await fetch(API_URL + "/loan-types")).json();
          this.loanTypes = response.data.map((d) => ({
            ...d,
            short: d.name.toLowerCase(),
          }));
        } catch (error) {
          // console.log(error);
        }
      },
    },
  };
</script>

<style scoped>
@import url('./style.css');

#loanspot-wrapper {
  max-width: 300px;
  margin: 0 auto;
  /* height: 250px; */
  background: white;
  border: 1px solid #1656C1;
  border-radius: 10px;
  overflow: hidden;
}

.logo-wrapper {
  margin: 10px auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-type--selected {
  border: 1px solid #1656C1 !important;
  color: #1656C1;
  font-family: SohneKraftig, sans-serif;
}

h3.meta {
  font-size: 0.7rem;
  margin: auto 0 0 auto;
  text-align: center;
}

h3.cta {
  text-transform: uppercase;
  margin-top: 10px;
  font-size: 1rem;
  font-weight: 600;
  color: #1656C1;
  border-bottom: 1px solid #1656C1;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
</style>
