import 'document-register-element/build/document-register-element'
import Vue from 'vue'
import App from './App.vue'
import './style.css'

import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

Vue.customElement('loanspot-widget', App, {
  // shadow: true,
  beforeCreateVueInstance(root) {
    const rootNode = root.el.getRootNode();

    if (rootNode instanceof ShadowRoot) {
      root.shadowRoot = rootNode;
    } else {
      root.shadowRoot = document.head;
    }
    return root;
  },
})
